.footer .section-1{
    padding: 4rem 2rem;
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: start;
}

.footer-verdex img{
    width: 350px;
} 

.footer h2{
    color: rgb(18, 29, 51);
    margin-bottom: 32px;
    font-size: 1.125rem;
    font-weight:500;
    line-height: 1.5rem;

}

.footer-resources p{
    color: rgb(103, 113, 133);
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    transition: color 0.3s;
    margin-top: 1rem;
    

}

.footer-auth button { 
    font-size: 14px;
    height: 44px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0.75rem 1.375rem;
    font-weight: 500;
    border-radius: 0.5rem;
    border: 0.125rem solid transparent;
    cursor: pointer;
    transition: 0.3s;
    white-space: nowrap;
    color: rgb(18, 29, 51);
    margin-right: 1rem;
}

.footer-auth button:first-child { 
    background-color: white;
    color:rgb(12, 108, 242);
        border: 2px solid  rgb(12, 108, 242);
}

.footer-auth button:last-child { 
    background-color: rgb(12, 108, 242);
    color: white;
}

.copyright{
    border-top: 1px solid rgb(223, 227, 235);
    text-align: center;
    padding: 1.5rem 2rem;
}

@media screen and (max-width:768px){
    .footer .section-1{
        flex-direction: column;
    }
    .footer-auth h2{
        margin-top: 4rem;
    }
    .footer-resources h2{
        margin-top: 4rem;

    }

}