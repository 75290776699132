.signup-header {
    position: relative;
    padding: .5rem .5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: url('../../resources/images/blockchain.png') no-repeat center center;
    background-size: cover;
    z-index: 1;
}

.signup-header::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,212,255,1) 100%);
    opacity: 0.7; /* Adjust opacity as needed */
    z-index: -1;
}


.logo img{
    height: 60px;
}

.signup-signin{
    display: flex;
    margin-right: 1rem;
    font-size: 14px;
    align-items: center;
}
.signup-signin button{
    background-color: transparent;
    border-radius: 16px;
    font-weight: 600;
    text-align: center;
    cursor: pointer;
    border-radius: 32px;
    box-sizing: border-box;
    font-size: 14px;
    line-height: 21px;
    padding: 6px 12px;
    color: white;
    border: 1px solid rgb(223, 227, 235); 
    display: inline-block;
    text-decoration: none;

}


.signup-signin p{
    color: white;
    margin-right: 1rem;
}

.signup-form{
    width: 100%;
    background: rgb(240, 242, 247);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    padding: 24px;
  
    display:flex;
   
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: start;
    justify-content: center;
    min-height: 100vh;
}

.signup-form form{
    display: flex;
    width: calc(100% - 32px);
    flex-direction: column;
    align-items: flex-start;
    -webkit-box-pack: start;
    justify-content: flex-start;
    border-radius: 16px;
    padding: 16px;
    background-color: white;
    gap: 16px;
    width: calc(100% - 48px);
    max-width: 550px;
    /* min-height: 637px; */
    padding: 24px;
    
}
.signup-form .form-head{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
   

}
.signup-form .form-head span{
    position: relative;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    width: 88px;
    height: 88px;
    border-radius: 50%;
    background-color: rgb(240, 242, 247);
    margin-bottom: 24px;
}

.signup-form .form-head h3{
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0px;
    margin-block: 0px;
    color: rgb(18, 29, 51);
}

.signup-form .form-head p{
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0px;
    color: rgb(103, 113, 132);
}

.form-input{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
}

.form-input label{
  
        font-size: 14px;
        font-weight: 600;
        line-height: 21px;
        height: 32px;
        color: rgb(18, 29, 51);

}

.form-input input{
    box-sizing: border-box;
    border-radius: 16px;
    padding: 12px 16px;
    border: 1px solid transparent;
    background-color: rgb(240, 242, 247);
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    width: 100%;
    color: rgb(18, 29, 51);
}
.form-input input:focus{
    outline: none;
}
.password-input{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(240, 242, 247);
    border-radius: 16px;
    width: 100%;
    border: 1px solid transparent;
   
}
.password-input svg{

    margin-left: -2rem;
}

.password-input input{
    border: none !important;
}

.form-input select{
    box-sizing: border-box;
    width: 100%;
    border-radius: 16px;
    padding: 12px 16px;
    border: 1px solid transparent;
    background-color: rgb(240, 242, 247);
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: rgb(18, 29, 51);
}
.signup-form .enable{
    display: flex;
    gap: 8px;
    font-weight: 600;
    letter-spacing: 0px;
    text-align: center;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 32px;
    box-sizing: border-box;
    width: 100%;
    font-size: 16px;
    line-height: 24px;
    padding: 12px 24px;
    color: rgb(255, 255, 255);
    background-color: rgb(12, 108, 242);
    border: none;
}
.signup-form .disabled{
    display: flex;
    gap: 8px;
    font-weight: 600;
    letter-spacing: 0px;
    text-align: center;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 32px;
    box-sizing: border-box;
    width: 100%;
    font-size: 16px;
    line-height: 24px;
    padding: 12px 24px;
    color: rgb(255, 255, 255);
    background-color: rgba(12, 108, 242, 0.466);
    border: none;
}
.forgot-pass{
    display: flex;
    gap: 8px;
    font-weight: 600;
    letter-spacing: 0px;
    text-align: center;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 32px;
    box-sizing: border-box;
    width: 100%;
    font-size: 16px;
    line-height: 24px;
    padding: 12px 24px;
    border: 1px solid rgb(12, 108, 242);
    color:rgb(12, 108, 242);
    background-color: rgb(255, 255, 255);
   
}

.form-input input::placeholder{
    font-size: 14px;

}
.form-terms input[type="checkbox"] {
    /* Your styles here */
    width: 20px;
    height: 20px;
    accent-color: #0656eb; /* Changes the color in supporting browsers */
    cursor: pointer;
}
.mobile-logo{
    display: none;
}


@media screen and (max-width:768px){

    .desktop-logo{
        display: none;
    }
    .mobile-logo{
        display: block;
    }
   
    .signup-form form {
        margin-top: 5rem;
        width: calc(100% - 1px);
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: max-content;
        padding: 20px;
    }
    .forgot-pass{
        font-size: 14px;
    }
    .signup-form .form-head p {
        font-size: 14px;
      
    }
    .signup-form .form-head h3 {
        font-size: 18px;
       
    }
    .form-input label {
        font-size: 12px;

    }
    .form-input input::placeholder{
        font-size: 12px;
    
    }
    .form-input select{
        font-size: 12px;
    
    }
    .signup-form .disabled {
       
        font-size: 14px;
        
    }
    .signup-form .enable {
       
        font-size: 14px;
        
    }

}