.wallet {
    box-sizing: border-box !important;
    width: 100%;
    padding: 0rem 1rem;
    background-color: #181A20;
    height: 100vh;
    
    overflow: auto; /* Prevent scrolling */
}

.top-level {
    color: rgb(255, 255, 255);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 0.3rem;
    border-bottom: 1px solid rgba(128, 128, 128, 0.185);
}

.top-level span {
    font-size: 25px;
    cursor: pointer;
}

.top-level p {
    font-size: 20px;
}

.user-wallet {
    color: white;
    display: flex;
    justify-content: space-between;
    margin-top: 1.5rem;
}

.user-wallet .main-wallet p {
    padding: 0.5rem;
    font-size: 25px;
    font-weight: 700;
}

.main-wallet div {
    display: flex;
    align-items: center;
    font-size: 25px;
}

.main-wallet div span {
    font-size: 20px;
    cursor: pointer;
}

.user-wallet-copy span {
    font-size: 20px;
    cursor: pointer;
}

.send-receive {
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.send-receive p {
    font-size: 15px;
    text-align: center;
    font-weight: 500;
    margin-top: 1rem;
}

.send-receive div span {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    background-color: #404041;
    height: 50px;
    width: 50px;
    border-radius: 50px;
    font-weight: 400;cursor: pointer;
    
}

.crypto h4 {
    display: flex;
    justify-content: center;
    color: white;
    margin-top: 3rem;
    font-size: 20px;
    text-align: center;
}

.crypto-token {
    display: flex;
    justify-content: space-between;
    align-items: end;
    padding: 0.8rem 0;
    cursor: pointer;
    margin: 1rem ;
}

.token {
    display: flex;
    align-items: center;
    justify-content: start;
}

.token-img img {
    height: 35px;
}

.crypto-data {
    margin-left: 1rem;
}

.crypto-data p {
    text-transform: uppercase;
    font-size: 15px;
    color: white;
}

.crypto-data span {
    text-transform: capitalize;
    font-size: 10px;
}

.token-price {
    color: #6d6d70 !important;
    font-size: 13px !important;
    font-weight: 500;
}

.token-name {
    font-size: 8px !important;
    color: white;
    margin-left: 0.5rem;
    background-color: #3d3d3d;
    border-radius: 50px;
    padding: 1px 6px;
}

.crypto-balances {
    color: rgb(92, 92, 92);
    text-align: end;
}

.crypto-balances p {
    color: white;
    font-size: 15px;
}

.crypto-balances span {
    font-size: 13px;
}

.choose-receive {
  background-color: #181a20;
  box-sizing: border-box;
  color: #fff;
  min-height: 100vh;
  position: absolute;
  top: 0;
  width: 100%;
   
   
}

.choose-receive-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    font-size: 20px;
    border-bottom: 1px solid rgba(128, 128, 128, 0.185);
}

.choose-receive-top span {
    font-size: 20px;
    cursor: pointer;
}

.second-token-name {
    font-size: 10px !important;
    text-transform: capitalize !important;
    color: #6d6d70;
    font-weight: 300;
}

.receiving-crypto {
    padding-bottom: 1rem;
    position: absolute;
    top: 0;
    width: 100%;
    
    color: white;
    background-color: #181A20;
    min-height: 100vh;
    overflow: hidden; /* Prevent scrolling */
}

.receive-data img {
    height: 35px;
}

.receive {
    overflow: hidden; /* Prevent scrolling */
}

.receive-data {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 60%;
    overflow: hidden; /* Prevent scrolling */
    margin-top: 1rem;
}

.receive-token-data {
    display: flex;
    text-transform: uppercase;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
}

.receive-token-data p {
    margin-left: 0.5rem;
    font-weight: 500;
}

.qrcode {
    background-color: white;
    color: black;
    padding: 1rem 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.notice {
    display: flex;
    font-size: 10px;
    align-items: center;
    justify-content: center;
    background-color: #362e165e;
    height: 50px;
    text-align: center;
    width: 90%;
    border-radius: 5px;
    color: #cc9a04;
    padding: 5px;
}

.notice span {
    display: flex;
    padding: 3px;
    background-color: #cc9a04;
    color: black;
    border-radius: 50%;
    margin-right: 0.6rem;
}

.notice-section {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
}

.receiving-crypto .send-receive{

    display: flex;
    justify-content: center;
    margin-top: 1rem;
    flex-direction: column;
}
.receiving-crypto .send-receive{
    text-align: start !important;
}
.coinpage{
    position: absolute;
    top: 0;
    width: 100%;
    color: white;
    background-color: #181A20;
    height: 100vh;
    box-sizing: border-box;

}
.coinpage .choose-receive div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.coinpage .choose-receive-top p{
    text-transform: uppercase;
    margin-bottom: -.5rem;
    font-weight: 600;
   
}

.coinpage .choose-receive-top div span{
    text-transform: capitalize;
    font-size: 10px;
    color: #6d6d70;
}
.cryptosection-data{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.cryptosection-data img{
    height: 60px;

}
.coinpage-cryptosection{
   margin-top: 4rem;
    padding: 1rem;
    border-bottom: 1px solid rgba(128, 128, 128, 0.185);
}
.cryptosection-data p{
    font-size: 30px;
    font-weight: 600;
    text-transform: uppercase;
    margin: 1rem 0 .3rem 0;
}









.crypto-send-container {
    padding: 20px;
    /* background-color: #f7f8fa; */
    /* border-radius: 8px; */
    /* max-width: 400px; */
    /* margin: auto; */
    position: absolute;
    top: 0;
    width: 100%;
    color: white;
    background-color: #181A20;
    height: 100%;
    box-sizing: border-box;
    overflow: hidden;
}

.choose-receive-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.choose-receive-top p {
    font-size: 20px;
    font-weight: bold;
}

.send-section form {
    display: flex;
    flex-direction: column;
}

.input-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #181A20;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-bottom: 15px;
}

.input-section input {
    border: none;
    outline: none;
    flex-grow: 1;
    background-color: #181A20;
    color: #ffffff;
}
.choose-receive-top span{
    text-transform: uppercase

}
.input-section p {
    margin: 0;
    cursor: pointer;
    color: #007bff;
    display: flex;
    align-items: center;
}

.qr-scanner video {
    margin-top: 10px;
    border-radius: 8px;
}

.send-section button {
    background-color: #007bff;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    position: absolute;
    bottom: 10%;
    width: 90%;
   
}

.send-section button:hover {
    background-color: #0056b3;
}




























.swap-page {
  background-color: #f5f7fa;
    padding: 20px;
    /* border-radius: 10px;
    max-width: 400px; */
    margin: 0 auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 0;
    width: 100%;
    color: white;
    background-color: #181A20;
    height: 100%;
    box-sizing: border-box;
    overflow: hidden;
}
.swap-error{
  color: red;
  font-size: 10px;
}

@media (max-width: 768px) {
  .swap-page {
      padding: 15px;
      max-width: 100%;
      border-radius: 0;
  }
}

.choose-swap-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.choose-swap-top p {
  font-size: 18px;
  font-weight: bold;
  color: #E2E2E2;
}

.choose-swap-top span {
  cursor: pointer;
  font-size: 20px;
  color: #007bff;
  transition: color 0.3s ease;
}

.choose-swap-top span:hover {
  color: #0056b3;
}

.swap-section {
  margin-bottom: 20px;
}

.swap-token {
  margin-bottom: 15px;
}

.swap-token p {
  font-size: 14px;
  margin-bottom: 8px;
  color: #B5B5B5;
}

.token-input {
  display: flex;
  align-items: center;
  background-color: #181A20;
  border: 1px solid #ffffff;
  padding: 15px;
  border-radius: 5px;
  overflow-wrap: anywhere;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.token-input:hover {
  background-color: #20232a;
  border-color: #007bff;
}

.token-input img {
  height: 35px;
  margin-right: 10px;
}

.token-input select {
  border: none;
  background-color: transparent;
  font-size: 16px;
  font-weight: bold;
  flex-grow: 1;
  cursor: pointer;
  margin-right: 10px;
  color: #ffffff;
  text-transform: uppercase;
  width: 50%;
}

.token-input select option {
  background-color: transparent;
  font-size: 16px;
  color: #181A20;
}

.token-input input {
  border: none;
  outline: none;
  font-size: 16px;
  width: 100%;
  max-width: 200px;
  font-weight: 700;
  text-align: right;
  background-color: #181A20;
  color: white;
}

.swap-btn {
  text-align: center;
  margin: 10px 0;
  cursor: pointer;
  font-size: 24px;
  color: #007bff;
  transition: transform 0.2s ease, color 0.3s ease;
}

.swap-btn:hover {
  transform: rotate(180deg);
  color: #0056b3;
}

.swap-action {
  text-align: center;
  margin-top: 20px;
}

.swap-action button {
  margin-top: 3rem;
  background-color: #007bff;
  color: white;
  font-size: 16px;
  padding: 12px 24px;
  border: none;
  border-radius: 5px;
  width: 100%;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.swap-action button:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

.disabled-btn {
  color: rgb(255, 255, 255);
  background-color: rgba(12, 108, 242, 0.5);
  cursor: not-allowed;
}


/* 
  Transfer Confirm css */





  .confirm-transfer{
    position: absolute;
    top: 0;
    width: 100%;
    color: white;
    background-color: #181A20;
    height: 100%;
    box-sizing: border-box;
    overflow: hidden;
    padding: 20px;
  }
.confirm-btn{
    display: flex;
    align-items: center;
    justify-content: center;
}
  .confirm-transfer button{
    margin-top: 3rem;
    background-color: #007bff;
    color: white;
    font-size: 16px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    width: 90%;
    cursor: pointer;
    transition: background-color 0.3s ease;
    bottom: 10%;
    position: absolute;
  }

  .amount-data{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .amount-data p{
    font-size: 30px;
    font-weight: 600;
    text-transform: uppercase;
    margin: 1rem 0 .3rem 0;
  }
  .amount-data span{
    color: #6d6d70;
  }
  .asset-name{
    text-transform: capitalize;
  }
  .confirm-asset-data{
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    background-color: #2d2d2e9a;
    padding: 20px;
  }
  .confirm-asset-data p{
    color: #6d6d70;
    font-size: 16px;
    font-weight: 500;
  }
  .asset-data{
    display: flex;
    margin-top: 1rem;
    justify-content: space-between;
  }
  .asset-data span{
    font-size: 13px;
  }


  /* History */

  .history-data{
    position: absolute;
    top: 0;
    width: 100%;
    color: white;
    background-color: #181A20;
    height: 100%;
    box-sizing: border-box;
    overflow: auto;
    padding: 20px;
  }

  .notification .history-data{
    position: absolute;
    top: 0;
    width: 100%;
    color: white;
    background-color: #181A20;
    height: 100%;
    box-sizing: border-box;
    overflow: auto;
    padding: 5px;
  }

  .notification .history-data .address-data span{
    
    color: white;
   
  }
  .wallet-noti{
    position: relative;
  }
  .wallet-noti p{
    position: absolute;
    top: 0;
    background-color: red;
    font-size: 11px;
    border-radius: 50px;
    height: 15px;
    width: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .transaction-data{
    margin-top: 2rem;
  }
  .date{
    font-size: 15px;
    color: #6d6d70;
    font-weight: 600;
  }

  .transactions{
    display: flex;
    justify-content: space-between;
    align-items: center;


  }
  .transactions .arrow{
   font-size: 15px;
   padding: 10px;
   background-color: #373738;
   border-radius: 50px;
   display: flex;
   justify-content: center;
   align-items: center;
   
  }
  .address-data{
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
    margin-left: .5rem;
    font-size: 15px;
  }
  .arrow-address{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .address-data span{
    font-size: 10px;
    color: #6d6d70;

  }

  .amount-data-history{
    display: flex;
    flex-direction: column;
    color: white;
    font-size: 15px;
  }

  .amount-data-history span{
    color: rgb(4, 180, 4);
  }
  .amount-data-history h4{
    font-size: 10px;
    color: white;

  }

  .amount-data-history p{
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 600;
    color: white;
  }

 /* Wallet Sidebar */

 .wallet-sidebar{
  position: absolute;
  background-color: #181A20;
  color: #ffffff;
  top: 0;
  padding: 3rem .5rem;
  width: 100%;
  height:100%;
  
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);

 }
 .sidebar-header-section img{
  height: 100px;
  
 }
 .wallet-sidebar svg{
  position: absolute;
  right: 0;
  top: 0;
  margin: 1rem;
 }
 .menu-items{
  width: 100%;
 }
 .menu-items p{
  margin-top:  2rem;
  font-size: 15px;
   cursor: pointer;
  border-bottom: 1px solid rgba(150, 150, 150, 0.18);
 }


.sidebar-header-section{

 }

 .slide-in-left {
	-webkit-animation: slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

 /* ----------------------------------------------
 * Generated by Animista on 2024-10-21 0:40:52
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-left
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
            transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
            transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
