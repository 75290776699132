/* General Header Styling */
.header {
    background-color:#161A42;
    padding: .5rem .5rem;
    display: flex;
    color: white;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid white;
    position: fixed;
    top:0;
    width: 100%;
    z-index: 100;

}

.headerlogo img {
    height: 60px;
}

.header .menu {
    display: flex;
}

.header .menu p {
    margin-right: 1rem;
    cursor: pointer;
}

.header .mobmenu {
    display: none;
}

.auth button { 
    font-size: 14px;
    height: 44px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0.75rem 1.375rem;
    font-weight: 500;
    border-radius: 0.5rem;
    border: 0.125rem solid transparent;
    cursor: pointer;
    transition: 0.3s;
    white-space: nowrap;
    color: rgb(18, 29, 51);
    margin-right: 1rem;
}
.mobmenu-icon{
    display: none;
}
.auth .login-btn { 
    background-color: transparent;
    color: white;
    border: 2px solid white;
}

.auth .signup-btn { 
    background-color: white;
}
.mobmenu-canvas{
    position: absolute;
    display: flex;
    flex-direction: column;
    background-color: white;
    width: 100%;
    height: fit-content;
    color: white;
    top: 0;
    right: 0;
    padding: .5rem .5rem;
    color: rgb(18, 29, 51);
    justify-content: space-between;

}
/* Mobile Styles */
@media screen and (max-width: 768px) {
    .headerlogo img {
        width: 250px;
        height: 65px;
    }
    .header .menu {
        display: none;
    }
    .header .auth {
        display: none;
    }
    .header .mobmenu {
        display: block;
        font-weight: 700;
    }
    .mobmenu-auth button { 
        font-size: 14px;
        height: 44px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        padding: 0.75rem 1.375rem;
        font-weight: 500;
        border-radius: 0.5rem;
        border: 0.125rem solid transparent;
        cursor: pointer;
        transition: 0.3s;
        white-space: nowrap;
        
        margin-right: 1rem;
    }
    
    .mobmenu-auth button:first-child { 
        background-color: transparent;
        color:rgb(12, 108, 242);
        border: 2px solid  rgb(12, 108, 242);
    }
    
    .mobmenu-auth button:last-child { 
        background-color: rgb(12, 108, 242);
        color: white;
    }
    .mobmenu-logo{
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid rgb(223, 227, 235);
        
    }
    .mobmenu-logo img{
        width: 250px;
        height: 65px;

    }
    .mobmenu-items p{
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        padding: 1rem;
        color: rgb(18, 29, 51);
        transition: background-color 300ms, color 300ms;
        font-weight: 500;
        border-top: 1px solid rgba(223, 227, 235, 0.24);
       

    }
    .mobmenu-items h3{
        background-color: rgb(247, 248, 250);
        border-bottom: 1px solid rgb(240, 242, 247);
        padding: 2rem 1rem 1rem;
        font-weight: 500;
        font-size: 0.875rem;
        letter-spacing: 1px;
        line-height: 1;
        text-transform: uppercase;
        color: rgb(103, 113, 133);
    }
    .mobmenu-auth h3{
        background-color: rgb(247, 248, 250);
        border-bottom: 1px solid rgb(240, 242, 247);
        padding: 2rem 1rem 1rem;
        font-weight: 500;
        font-size: 0.875rem;
        letter-spacing: 1px;
        line-height: 1;
        text-transform: uppercase;
        color: rgb(103, 113, 133);
        margin-bottom: 1.5rem;
        text-align: start;
    }
     .mobmenu-items{
        margin-top: 1rem;
        /* border-bottom: 1px solid #ffffff; */

    }
    .mobmenu-auth{
         text-align: end;
        vertical-align: bottom; 
        padding-bottom: 1rem;
    }
    .mobmenu-icon{
        display: block;
        z-index: 999;
        color: white;
    }

}




.slide-in-right {
	-webkit-animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2024-9-17 2:20:33
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-right
 * ----------------------------------------
 */
 @-webkit-keyframes slide-in-right {
    0% {
      -webkit-transform: translateX(1000px);
              transform: translateX(1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 1;
    }
  }
  @keyframes slide-in-right {
    0% {
      -webkit-transform: translateX(1000px);
              transform: translateX(1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 1;
    }
  }
  

  .slide-out-right {
	-webkit-animation: slide-out-right 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
	        animation: slide-out-right 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2024-9-17 2:28:5
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-out-right
 * ----------------------------------------
 */
 @-webkit-keyframes slide-out-right {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateX(1000px);
              transform: translateX(1000px);
      opacity: 0;
    }
  }
  @keyframes slide-out-right {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateX(1000px);
              transform: translateX(1000px);
      opacity: 0;
    }
  }
  