
.home{
    position: relative;
    overflow-x: hidden;
}
.homeheader{
    padding: 2.5rem 0;
    /* background-color:#060249; */
    border-bottom: 1px solid white;
}
.hero-section {
    position: relative;
    padding: 1rem 5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: url('../../resources/images/blockchain.png');
    background-size:contain;
    background-position: right center;
    z-index: 1;
    height: 100vh;
    align-items: center;
    color: white;
   
    
}
.home-faq{
    background: url('../../resources/images/homepage-exchange-shapes-bg@2x.png') no-repeat ;
    background-size: cover;
    position: relative;
    padding-bottom: 4rem;
}

.hero-section::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(90deg, rgba(22,26,66,1) 0%, rgba(9,9,121,1) 50%, rgba(0,212,255,1) 100%);
    opacity: 0.9; /* Adjust opacity as needed */
    z-index: -1;
}

.hero-section img{
    padding: 2rem 0;
    height:700px;

}
.hero-section h2{
    font-size: 3rem;
    font-weight: 600;
    line-height: 3.625rem;
    color: rgb(255, 255, 255);
    margin-bottom: 1.5rem;
}
.hero-section p{
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 2.125rem;
    margin-bottom: 2rem;
}
.newsletter input{
    width: 100%;
    max-width: 24.1875rem;
    background-color: rgba(255, 255, 255, 0.1);
    border: none;
    border-radius: 0.5rem;
    padding: 0.75rem 1rem;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: rgb(255, 255, 255);
}

.newsletter input::placeholder{
    color: white;
}

.newsletter button{
    font-size: 14px;
    height: 44px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0.75rem 1.375rem;
    font-weight: 500;
    border-radius: 0.5rem;
    border: 0.125rem solid transparent;
    cursor: pointer;
    transition: 0.3s;
    white-space: nowrap;
    color: rgb(18, 29, 51);
    margin-right: 1rem;
    margin-left: 1rem;
}

.home-market-token{
    background-image: linear-gradient(rgb(255, 255, 255), rgb(255, 255, 255));
    border-radius: 0.5rem;
    box-shadow: rgba(103, 113, 133, 0.2) 0.75rem 1.5rem 4rem 0rem;
    color: rgb(18, 29, 51);
    min-width: 0px;
    padding: 1.25rem;
    transition: background-image 0.6s;
    margin-top: -1rem;
    z-index: 99;

  margin-right: 10px; /* Space between tokens */
  vertical-align: top;
  overflow-x: auto; /* Enable horizontal scrolling */
  overflow-y: hidden; /* Hide vertical scrolling */
  white-space: nowrap;
    
}

.home-market{
   
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(12rem, auto));
    gap: 1.5rem;
    border-radius: 0.5rem;
    width: 100%;
    margin-bottom: 1.25rem;
    box-sizing: content-box;
    max-width: 62.5rem;
    margin: 0px auto;
    padding: 0px 2.5rem;
    padding-bottom: 5rem;
 
    
}

.home-market-token img{
    height: 2.5rem;
    width: 2.5rem;
}
.home-market-token p{
    text-transform: capitalize;
  
        display: flex;
        margin-bottom: 0.25rem;

}
.home-market-token p span{
    color: rgb(103, 113, 133);
    margin-left: 0.5rem;
    text-transform: uppercase;
    font-weight: 500;
}
.home-market-token-price{
    color: inherit;
    font-weight: 500;
    font-size: 1rem;
}
.home-market-token-price span{
 
         
        font-weight: 500;
        font-size: 1rem;
}
.mobmenu-canvas {
  position: fixed;
  width: 300px;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  z-index: 1000;
}

.slide-in-right {
  transform: translateX(0); /* Moves it into view */
  transition: transform 0.3s ease-in-out;
}

.slide-out-right {
  transform: translateX(100%); /* Moves it out of view */
  transition: transform 0.3s ease-in-out;
}

.simple{
    padding: 4rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgb(22,26,66);
    background: rgb(131,58,180);
    background: linear-gradient(90deg, rgba(131,58,180,1) 0%, rgba(253,29,29,1) 50%, rgba(252,176,69,1) 100%);
}

.simple-first h2{
    font-size: 3.25rem;
    text-align: center;
    color: white;
    line-height: 3.75rem;
}
.simple-first p{
    font-size: 1.125rem;
    text-align: center;
    color: white;
    line-height: 1.625rem;
    margin-top: 1.5rem;
    padding: 0 10rem;
}
.simple-second{
    display: flex;
    align-items: center;
     margin: 2rem 9rem;
    background-color: rgba(255, 255, 255, 0.555);
    border-radius: 30px;
    padding-bottom: 3.5rem!important;
    padding-top: 3.5rem!important;
    padding: 20px;
    color: white;

}
.simple-second img{
    width: 105%;

}
.simple-second h3{
    font-size: 2.25rem;
}
.simple-second p{
    font-size: 16px;
    text-align: start;
    color: white;
    line-height: 1.625rem;
    margin-top: 1.5rem;
    
}
.get-started{
    background: url('../../resources/images/banner-bg.svg') no-repeat ;
    background-size: cover;
    position: relative;
    padding: 3rem 4rem;
    background-color: #1E2329;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
} 
.get-started h3{
    color: white;
    font-size: 30px;
}
.get-started button{
    display: inline-flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    padding: 0.75rem 1.375rem;
    font-size: 1.125rem;
    font-weight: 500;
    letter-spacing: 0.01em;
    line-height: 144%;
    border-radius: 0.5rem;
    border: 0.125rem solid transparent;
    cursor: pointer;
    transition: 0.3s;
    white-space: nowrap;
    color: rgb(255, 255, 255);
    background:  rgb(12, 108, 242);
    margin-top: 2rem;
}
.market .home-market{
   margin-top: 8rem;
}
@media screen and (max-width:768px){
    .hero-section {
        position: relative;
        padding: 1rem 1rem;
        flex-direction: column;
        justify-content: center;
        overflow: hidden;
    }
    .hero-section img{
        padding: 0;
        height:500px;
    
    }
    .hero-section h2 {
        font-size: 2rem;
        font-weight: 600;
        line-height: 2.625rem;
        color: rgb(255, 255, 255);
        margin-bottom: 1.5rem;
    }
    .hero-section p {
        font-size: 16px;
        font-weight: 500;
        line-height: 1.7rem;
        margin-bottom: 2rem;
    }
    .newsletter button{
        margin-top: .5rem;

    }
    .hero-text{
        padding-top: 4rem;
    }
    .home-market{
        gap: 1.5rem .8rem;
        padding: 0px .2rem;
        padding-bottom: 5rem;
     
        
    }
    .home-market-token p{
        text-transform: capitalize;
      
            display: flex;
            margin-bottom: 0.25rem;
    
    }
    .home-market-token p span{
        color: rgb(103, 113, 133);
        margin-left: 0.5rem;
        text-transform: uppercase;
        font-weight: 500;
    }
    .home-market-token-price{
        color: inherit;
        font-weight: 500;
        font-size: .9rem;
    }
    .home-market-token-price span{    
            font-weight: 500;
            font-size: .9rem;
    }
    .simple-second{
        display: flex;
       flex-direction: column;
       margin: 2rem .8rem;
       background-color: rgb(255, 255, 255);
       color: #000;
    
    }
    .simple-second h3{
        font-size: 30px;
    }
    .simple-second p{
        font-size: 15px;
        text-align: start;
        color: #000;
        line-height: 1.625rem;
        margin-top: 1.5rem;
        
    }
    .simple-first h2{
        font-size: 40px;
        text-align: center;
        color: white;
        line-height: 3.75rem;
    }
    .simple-first p{
        font-size: 15px;
        text-align: center;
        color: white;
        line-height: 1.625rem;
        margin-top: 1.5rem;
        padding: 0 1rem;
    }
}