.faq-container {
    max-width: 100%;
    margin: 0 auto;
    padding: 20px;
  
  }
  

  
  .faq-container h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .faq-list {
    border: 1px solid #ddd;
    border-radius: 5px;
    
  }
  
  .faq-item {
    border-bottom: 1px solid #ddd;
    
  }
  
  .faq-item:last-child {
    border-bottom: none;
  }
  
  .faq-question {
  
    padding: 15px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(236, 245, 254, 0.877);
    font-size: 15px!important;
    /* color: #0C6CF2; */
    
  }
  
  .faq-question h3 {
    font-weight: 500!important;
    line-height: 28px!important;
    margin: 0;
  }
  
  .faq-icon {
    font-size: 20px;
    transition: transform 0.3s ease;
  }
  
  .faq-icon.active {
    transform: rotate(45deg);
  }
  
  .faq-answer {
    padding: 15px;
    background-color:#ecf5fea2;
    padding-left: 48px!important;
    padding-right: 48px!important;
    font-weight: 400!important;
    font-size: 13px!important;
    line-height: 24px!important;
  }




  @media screen and (max-width:768px){
    .faq-question {
  
        font-size: 13px!important;
      }
  }
  .scale-in-top {
	-webkit-animation: scale-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: scale-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2024-9-17 22:45:8
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-in-top
 * ----------------------------------------
 */
 @-webkit-keyframes scale-in-top {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
      -webkit-transform-origin: 50% 0%;
              transform-origin: 50% 0%;
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: 50% 0%;
              transform-origin: 50% 0%;
      opacity: 1;
    }
  }
  @keyframes scale-in-top {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
      -webkit-transform-origin: 50% 0%;
              transform-origin: 50% 0%;
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: 50% 0%;
              transform-origin: 50% 0%;
      opacity: 1;
    }
  }
  